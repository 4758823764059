import React from "react"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import SiniestrosLayout from "../../components/siniestros-layout"

import icoSura from "../../assets/old/img/logos-ase/ico-sura.png";

export default () => (
    <SiniestrosLayout subCompaniaActivo="sura">
        <Helmet>
            <title>Sura Seguros - Siniestros</title>
            <meta
                name="description"
                content="Servicio de asistencia Sura Seguros: 0800 999 76925."
            />
        </Helmet>

        <hr className="mb-4" />
        {/* Quotation */}
        <div className="card blog-card mb-2">
            <div className="card-body px-sm-5 py-5 bg-secondary">
                <img
                    className="float-md-right pb-2"
                    src={icoSura}
                    alt="Sura Seguros"
                />
                <h4 className="lead post-title text-muted">Sura Seguros</h4>
                <p className="">Servicio de asistencia: 0800 999 76925.</p>
            </div>
        </div>
        <p className="pt-5 pb-3">
          Para denunciar su siniestro de forma fácil y rápida puede ingresar a nuestro Sitio de Clientes "Mis Pólizas" desde cualquier dispositivo (PC, tablet o teléfono celular).
        </p>
        <p>
            Podrá ingresar a la plataforma web haciendo click en el siguiente botón: <a href="https://mispolizas.segurossura.com.ar/Login" target="_blank" rel="noopener noreferrer">MIS PÓLIZAS</a> O descargar la aplicación desde su dispositivo móvil: <a href="https://itunes.apple.com/ar/app/seguros-sura-argentina/id1315068644?mt=8" target="_blank" rel="noopener noreferrer">Apple Store</a> / <a href="https://play.google.com/store/apps/details?id=com.sura" target="_blank" rel="noopener noreferrer">Play Store</a>
        </p>
        <p>
            Es muy importante que al momento de denunciar el accidente relate detalladamente cómo ocurrieron los hechos y brinde TODOS los datos que recabó.
        </p>
        <p>
          No reconozca responsabilidad ni indemnizaciones. Si le formulan alguna reclamación, facilite la tarjeta especial para terceros que se encuentra incorporada en su póliza o en su defecto brinde sus datos e informe que deberán presentar su reclamo en SURA.
        </p>
        <p>
          No repare su vehículo sin la previa inspección de la Compañía.
        </p>
        <p>
          Si recibe una notificación judicial y reside en CABA o GBA preséntela en Sede Central ubicada en Boulevard Cecilia Grierson 255, CABA, Departamento de Legales dentro de las 24 horas de recibida. Para los asegurados con domicilio en el interior del país preséntela en la agencia más cercana (consultar mapa de agencias), dentro de las 24 horas de recibida.
        </p>

        <hr className="pb-4" />

    </SiniestrosLayout>
)
